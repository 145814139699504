import { useGetDocumentsQuery, useLazyGetDocumentsQuery } from '@api/documents';
import IconButton from '@components/button/IconButton';
import { ColumnDef } from '@g17eco/molecules/table';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { BasicAlert } from '@g17eco/molecules/alert';
import { getDateRange } from '@g17eco/molecules/time-range-selector';
import { DocumentItem } from '@g17eco/types/document';
import { useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { EvidenceFilters } from './EvidenceFilters';
import { generateDocumentsFlexSearchMap } from '@features/admin-dashboard/utils';
import { EvidenceFile, ExistingEvidenceFile } from '@components/survey/question/questionInterfaces';
import { UtrvStatus } from '@constants/status';
import { DateRangeType } from '@g17eco/types/common';
import { TIME_RANGE_YEARLY } from '@utils/date';
import { BlockingLoader } from '@g17eco/atoms/loader';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import { InfiniteScrollTable } from '@g17eco/molecules/table/InfiniteScrollTable';

export type FilterParams = {
  searchText: string;
  type: string;
  format: string;
  dateRange: DateRangeType;
};

interface Props {
  handleFilesAdded: (files: EvidenceFile[]) => void;
  existingFiles?: EvidenceFile[];
  files: EvidenceFile[];
  initiativeId: string;
  isOpen: boolean;
  toggle: () => void;
}

const LIMIT = 10;

const mapDocumentsToEvidenceFiles = (docs: DocumentItem[]): ExistingEvidenceFile[] => {
  return docs.map((doc) => ({
    _id: doc._id,
    metadata: doc.metadata,
    type: 'file',
    userId: doc.userId,
    path: doc.path,
    size: doc.size,
    status: UtrvStatus.Updated, // to allow remove evidence to be added
    public: doc.public,
    created: doc.created,
    url: doc.url,
    ownerSubType: doc.ownerSubType,
    ownerId: doc.ownerId,
  }));
};

const isExistingEvidence = (evidence: EvidenceFile): evidence is ExistingEvidenceFile => '_id' in evidence;

const extractDocuments = (
  response: { documents: DocumentItem[]; nextCursor?: string; hasNextPage: boolean } | undefined
) => {
  if (!response) {
    return {
      items: [],
      hasNextPage: false,
    };
  }
  return {
    items: response.documents,
    nextCursor: response.nextCursor,
    hasNextPage: response.hasNextPage,
  };
};

const getDefaultFilters = () => {
  return {
    searchText: '',
    type: '',
    format: '',
    dateRange: getDateRange(TIME_RANGE_YEARLY),
  };
};

export const EvidenceModal = ({
  handleFilesAdded,
  files,
  existingFiles = [],
  initiativeId,
  isOpen,
  toggle,
}: Props) => {
  const [filters, setFilters] = useState<FilterParams>(getDefaultFilters());
  const initialParams = { initiativeId, limit: LIMIT, ...filters.dateRange };
  const [documents, setDocuments] = useState<DocumentItem[]>([]);
  const { data, isFetching, error } = useGetDocumentsQuery(initialParams);

  const [getDocuments] = useLazyGetDocumentsQuery();

  const { items: visibleDocuments, handleScroll } = useInfiniteScroll({
    initialData: data,
    fetchData: getDocuments,
    extractData: extractDocuments,
    initialParams,
  });

  const isDocumentAdded = (docId: string) => documents.some((doc) => doc._id === docId);
  const isDocumentDisabled = (docId: string) => existingFiles.some((doc) => '_id' in doc && doc._id === docId);

  const documentFormatOptions = useMemo(() => {
    const uniqueExtensions = new Set(visibleDocuments.map((doc) => doc.metadata.extension));

    return Array.from(uniqueExtensions).map((extension) => ({
      value: extension,
      label: extension,
    }));
  }, [visibleDocuments]);

  useEffect(() => {
    const existing = visibleDocuments.filter((document) =>
      files.filter(isExistingEvidence).some((file) => file._id === document._id)
    );
    setDocuments((prev) => [...existing, ...prev.filter((doc) => !existing.some((e) => e._id === doc._id))]);
  }, [files, visibleDocuments]);

  const columns: ColumnDef<DocumentItem>[] = [
    {
      accessorKey: 'title',
      header: 'File name',
    },
    {
      accessorKey: 'ownerSubType',
      header: 'Type',
    },
    { accessorKey: 'metadata.extension', header: 'Format' },
    {
      id: 'add-button',
      header: '',
      meta: {
        headerProps: {
          style: {
            width: 50,
          },
        },
      },
      cell: ({ row }) => {
        if (isDocumentAdded(row.original._id) || isDocumentDisabled(row.original._id)) {
          return (
           <SimpleTooltip text={isDocumentDisabled(row.original._id) ? 'Already in existing evidences' : ''}>
            <IconButton
              outline
              icon='fal fa-xmark'
              color='error'
              disabled={isDocumentDisabled(row.original._id)}
              className='text-ThemeDangerMedium border border-ThemeDangerMedium'
              onClick={() => {
                setDocuments((prev) => prev.filter((file) => file._id !== row.original._id));
              }}
            />
           </SimpleTooltip>
          );
        }
        return (
          <IconButton
            outline
            icon='fal fa-plus'
            color='transparent'
            onClick={() => {
              setDocuments((prev) => [...prev, row.original]);
            }}
          />
        );
      },
    },
  ];
  const filteredRows: DocumentItem[] = useMemo(() => {
    const { searchText, type, format } = filters;
    let rows = visibleDocuments ?? [];
    if (searchText) {
      const searchIndex = generateDocumentsFlexSearchMap(visibleDocuments);
      const result = searchIndex.search(searchText);
      const matchedIds = new Set(result.map((item) => item.result).flat());
      rows = rows.filter((doc) => matchedIds.has(doc._id));
    }
    if (type) {
      rows = rows.filter((doc) => doc.ownerSubType === type);
    }
    if (format) {
      rows = rows.filter((doc) => doc.metadata.extension === format);
    }
    return rows;
  }, [filters, visibleDocuments]);

  const handleChangeFilters = (key: keyof FilterParams, value?: string) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeTimeRange = (dateRange?: DateRangeType) => {
    if (!dateRange) {
      return;
    }
    setFilters((prev) => ({
      ...prev,
      dateRange,
    }));
  };

  const handleClose = () => {
    setFilters(getDefaultFilters());
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} backdrop='static'>
      <ModalHeader toggle={handleClose}>Add evidence</ModalHeader>
      <ModalBody>
        {error ? <BasicAlert type='danger'>{error.message}</BasicAlert> : null}
        {isFetching ? <BlockingLoader /> : null}
        <EvidenceFilters
          documentFormatOptions={documentFormatOptions}
          filters={filters}
          onChangeFilters={handleChangeFilters}
          onChangeTimeRange={handleChangeTimeRange}
        />
        {filteredRows.length === 0 ? (
          <BasicAlert className='mt-2' type='secondary'>
            No available documents
          </BasicAlert>
        ) : (
          <InfiniteScrollTable
            columns={columns}
            data={filteredRows}
            handleScroll={handleScroll}
            noData={<BasicAlert type='secondary'>No documents found</BasicAlert>}
          />
        )}
      </ModalBody>
      <ModalFooter className='justify-content-center'>
        <Button color='link-secondary' onClick={handleClose}>
          Close
        </Button>
        <Button
          color='primary'
          onClick={() => {
            handleFilesAdded(mapDocumentsToEvidenceFiles(documents));
            toggle();
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
