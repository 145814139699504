import Dashboard, { DashboardRow, DashboardSection, DashboardSectionTitle } from '@components/dashboard';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { CTAdminBreadcrumbs } from '../breadcrumbs/CTAdminBreadcrumbs';
import { Button } from 'reactstrap';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';
import NotAuthorised from '@routes/not-authorised';
import { useToggle } from '@hooks/useToggle';
import { DocumentTable } from './DocumentTable';
import { DocumentUpload } from './DocumentUpload';
import { useUploadDocumentsMutation } from '@api/documents';
import { useSiteAlert } from '@hooks/useSiteAlert';

const renderUploadButton = ({ disabled, toggleUpload }: { disabled?: boolean; toggleUpload: () => void }) => {
  return [
    <Button key='upload-btn' disabled={disabled} onClick={() => toggleUpload()} color='secondary'>
      <i className='fal fa-files-medical mr-2' />
      Upload documents
    </Button>,
  ];
};

export const DocumentLibraryRoute = () => {
  const [openUpload, toggleUpload] = useToggle(false);
  const { initiativeId } = useParams<{ initiativeId: string }>();
  const { path } = useRouteMatch();
  const isStaffUser = useAppSelector(isStaff);
  const [uploadDocument, { isLoading: isUploading }] = useUploadDocumentsMutation();
  const { addSiteAlert, addSiteError } = useSiteAlert();

  if (!isStaffUser) {
    return <NotAuthorised />;
  }

  const handleFilesAdded = (files: File[]) => {
    if (files.length === 0) {
      return;
    }
    uploadDocument({ initiativeId, data: { files } })
      .unwrap()
      .then(({fulfilled, rejected}) => {
        addSiteAlert({
          content: (
            <div className='text-center'>
              <i className='fal fa-check-circle mr-2' />
              {fulfilled.length} document(s) uploaded. {rejected.length} document(s) failed to upload.
            </div>
          ),
        });
      })
      .catch((error) => {
        addSiteError(error);
      })
      .finally(() => {
        toggleUpload();
      });
  };

  return (
    <Switch>
      <Route path={path}>
        <Dashboard>
          <DashboardRow>
            <CTAdminBreadcrumbs breadcrumbs={[{ label: 'Evidence library' }]} initiativeId={initiativeId} />
          </DashboardRow>
          <DashboardSectionTitle
            title='Document Library'
            buttons={renderUploadButton({ disabled: isUploading, toggleUpload })}
          />
          <DashboardRow isVisible={openUpload} classes={{ children: 'flex-lg-column' }}>
            <DocumentUpload isUploading={isUploading} handleFilesAdded={handleFilesAdded} />
          </DashboardRow>
          <DashboardSection>
            <DocumentTable initiativeId={initiativeId} />
          </DashboardSection>
        </Dashboard>
      </Route>
    </Switch>
  );
};
