import { Table, TableProps } from './Table';

const MAX_PAGE_SIZE = 999999;

export const InfiniteScrollTable = <T extends object = Record<string, unknown>>(
  props: TableProps<T> & {
    handleScroll: (e: React.UIEvent<HTMLDivElement>) => void;
  }
) => {
  const { data, noData, columns, handleScroll } = props;

  return (
    <div onScroll={handleScroll} style={{ height: '400px', overflowY: 'scroll' }}>
      <Table pageSize={MAX_PAGE_SIZE} columns={columns} data={data} className='mt-2' noData={noData} />
    </div>
  );
};
