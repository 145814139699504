import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { QueryArgFrom, ResultTypeFrom } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useEffect, useState } from 'react';

interface UseInfiniteScrollProps<T, Params extends QueryArgFrom<D>, D extends QueryDefinition<any, any, any, any>> {
  initialData: ResultTypeFrom<D> | undefined;
  fetchData: LazyQueryTrigger<D>;
  extractData: (response: ResultTypeFrom<D> | undefined) => { items: T[]; nextCursor?: string; hasNextPage: boolean };
  initialParams: Params;
}

export const useInfiniteScroll = <T, Params extends QueryArgFrom<D>, D extends QueryDefinition<any, any, any, any>>({
  initialData,
  fetchData,
  extractData,
  initialParams,
}: UseInfiniteScrollProps<T, Params, D>) => {
  const [items, setItems] = useState<T[]>([]);
  const [pagination, setPagination] = useState<{ cursor?: string; hasNextPage: boolean }>({ hasNextPage: false });

  useEffect(() => {
    if (initialData) {
      const { items, nextCursor, hasNextPage } = extractData(initialData);
      setItems(items);
      setPagination({
        cursor: nextCursor,
        hasNextPage,
      });
    }
  }, [initialData]);

  const fetchMore = () => {
    if (!pagination.hasNextPage) {
      return;
    }

    fetchData({ ...initialParams, cursor: pagination.cursor }).then(({ data }) => {
      const { items: newItems, nextCursor, hasNextPage } = extractData(data);
      setItems((prev) => [...prev, ...newItems]);
      setPagination({ cursor: nextCursor, hasNextPage });
    });
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom = e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.clientHeight;
    if (bottom) {
      fetchMore();
    }
  };

  return { items, handleScroll };
};
