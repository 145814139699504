import { DebouncedSearchBox } from '@g17eco/molecules/search';
import { SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { TimeRangeSelector } from '@g17eco/molecules/time-range-selector';
import { FilterParams } from './EvidenceModal';
import { documentOptions } from '@apps/company-tracker/components/document-library/utils';
import { DateRangeType } from '@g17eco/types/common';

export const EvidenceFilters = ({
  documentFormatOptions,
  filters,
  onChangeFilters,
  onChangeTimeRange,
}: {
  documentFormatOptions: { label: string; value: string }[];
  filters: FilterParams;
  onChangeFilters: (key: keyof FilterParams, value?: string) => void;
  onChangeTimeRange: (value?: DateRangeType) => void;
}) => {
  return (
    <div>
      <div className='d-flex gap-3 align-items-center mb-2'>
        <DebouncedSearchBox
          placeholder='Search for file...'
          onTextChange={(value) => onChangeFilters('searchText', value)}
          classNames={{ wrapper: 'flex-fill' }}
        />
        <TimeRangeSelector
          dateRange={filters.dateRange}
          onChangeDateRange={(value) => onChangeTimeRange(value)}
          styleProps={{ wrapper: 'ms-2', isTransparent: true, isFlexibleSize: true }}
        />
      </div>
      <div className='row'>
        <div className='col-6'>
          <SelectFactory
            placeholder='All file types'
            selectType={SelectTypes.SingleSelect}
            options={documentOptions}
            value={documentOptions.find(({ value }) => value === filters.type)}
            onChange={(op) => onChangeFilters('type', op?.value)}
            isClearable={true}
          />
        </div>
        <div className='col-6'>
          <SelectFactory
            placeholder='All formats'
            selectType={SelectTypes.SingleSelect}
            options={documentFormatOptions}
            value={documentFormatOptions.find(({ value }) => value === filters.format)}
            onChange={(op) => onChangeFilters('format', op?.value)}
            isClearable={true}
          />
        </div>
      </div>
    </div>
  );
};
