import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isTextNode,
  $setSelection,
  $createRangeSelection,
} from 'lexical';
import { $createIxbrlNode, type IXBRLNodeProps, $isXbrlNode } from './IXBRLNode';

/**
 * Lexical-style utility to wrap current selection in a new IXBRLNode.
 * Skips nodes already wrapped and safely handles nested selections.
 */
export function $wrapSelectionInIxbrlNode(props: IXBRLNodeProps): void {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    console.warn('[$wrapSelectionInIxbrlNode] Aborting: not a range selection');
    return;
  }

  const [anchor, focus] = [selection.anchor, selection.focus].sort((a, b) => {
    return a.getNode().getKey() < b.getNode().getKey() ? -1 : 1;
  });

  const anchorNode = anchor.getNode();
  const focusNode = focus.getNode();

  if (!anchorNode || !focusNode) {
    console.warn('[$wrapSelectionInIxbrlNode] Aborting: missing anchor or focus node');
    return;
  }

  // Split partial text nodes at selection boundaries
  if ($isTextNode(anchorNode) && anchor.offset > 0) {
    anchorNode.splitText(anchor.offset);
  }

  if ($isTextNode(focusNode) && focus.offset < focusNode.getTextContentSize()) {
    focusNode.splitText(focus.offset);
  }

  const selectedNodes = selection.getNodes();
  if (selectedNodes.length === 0) {
    console.warn('[$wrapSelectionInIxbrlNode] Aborting: no selected nodes found');
    return;
  }

  // Create the wrapper node
  const wrapperNode = $createIxbrlNode(props);
  const firstSelected = selectedNodes[0];

  // Insert wrapper node before the first selected node
  firstSelected.insertBefore(wrapperNode);

  // Move selected nodes into the wrapper, unless already wrapped
  for (const node of selectedNodes) {
    const parent = node.getParent();

    if (parent && $isXbrlNode(parent)) {
      console.warn('[$wrapSelectionInIxbrlNode] Skipping node already inside IXBRLNode:', node.getKey());
      continue;
    }

    wrapperNode.append(node);
  }

  // Restore selection around new wrapper
  const lastChild = wrapperNode.getChildren().at(-1) ?? wrapperNode;
  const newSelection = $createRangeSelection();

  newSelection.anchor.set(wrapperNode.getKey(), 0, 'element');

  if ($isElementNode(lastChild)) {
    newSelection.focus.set(lastChild.getKey(), 0, 'element');
  } else {
    newSelection.focus.set(lastChild.getKey(), lastChild.getTextContentSize(), 'text');
  }

  $setSelection(newSelection);
}
