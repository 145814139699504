import { ElementNode, SerializedElementNode, EditorConfig, LexicalNode } from 'lexical';
import { addClassNamesToElement } from '@lexical/utils';

export interface NonFractionFact {
  tag: 'ix:nonFraction';
  name: string;
  format?: string;
  factId: string;
  unitRef: string;
  contextRef: string;
  decimals?: number;
  scale?: number | string;
}

export interface IXBRLElementNodeSerialized extends SerializedElementNode {
  xbrl: NonFractionFact;
}

export type IXBRLNodeProps = NonFractionFact;


export class IXBRLNode extends ElementNode {
  private readonly xbrl: NonFractionFact;

  constructor(props: IXBRLNodeProps, key?: string) {
    super(key);
    this.xbrl = props;
  }

  static getType(): string {
    return 'ixbrl-element';
  }

  static clone(node: IXBRLNode): IXBRLNode {
    return new IXBRLNode(node.xbrl, node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {

    const element = document.createElement('span');
    addClassNamesToElement(element, 'ixbrl-element', config.theme.ixbrlNode);
    element.setAttribute('xbrlTag', this.xbrl.tag);
    element.setAttribute('unitRef', this.xbrl.unitRef);
    element.setAttribute('contextRef', this.xbrl.contextRef);
    element.setAttribute('factId', this.xbrl.factId);
    element.setAttribute('title', this.xbrl.name);
    if (this.xbrl.decimals) {
      element.setAttribute('decimals', this.xbrl.decimals.toString());
    }

    if (this.xbrl.scale) {
      element.setAttribute('scale', this.xbrl.scale.toString());
    }
    element.setAttribute('xbrlName', this.xbrl.name);
    return element;
  }

  updateDOM(): boolean {
    return false;
  }

  static importJSON(serializedNode: IXBRLElementNodeSerialized): IXBRLNode {
    return new IXBRLNode({
      tag: serializedNode.xbrl.tag,
      name: serializedNode.xbrl.name,
      unitRef: serializedNode.xbrl.unitRef,
      contextRef: serializedNode.xbrl.contextRef,
      factId: serializedNode.xbrl.factId,
      decimals: serializedNode.xbrl.decimals,
      scale: serializedNode.xbrl.scale,
    });
  }

  exportJSON(): IXBRLElementNodeSerialized {
    return {
      ...super.exportJSON(),
      xbrl: this.xbrl,
      type: this.getType(),
    };
  }

  getTagName(): string {
    return this.xbrl.tag;
  }

  getName(): string {
    return this.xbrl.name;
  }

  getXbrl() {
    return this.xbrl;
  }

  // Override to prevent text insertion at the end of IXBRL node
  canInsertTextAfter(): boolean {
    return false;
  }

  // Override to prevent text insertion at the beginning of IXBRL node
  canInsertTextBefore(): boolean {
    return false;
  }

  public canBeEmpty(): boolean {
    return false;
  }

  public isInline(): boolean {
    return true;
  }
}

export function $createIxbrlNode(props: IXBRLNodeProps): IXBRLNode {
  return new IXBRLNode(props);
}

export function $isXbrlNode(node: LexicalNode | null | undefined): node is IXBRLNode  {
  return node instanceof IXBRLNode;
}
