import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $createRangeSelection,
  $setSelection,
  LexicalNode,
  ElementNode,
} from 'lexical';
import { $isXbrlNode } from './IXBRLNode';

/**
 * Unwraps all IXBRLNodes found within a multi-node selection.
 * Moves their children out, removes the wrapper, and restores selection.
 */
export function $unwrapIxbrlNode(): void {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    console.warn('[$unwrapIxbrlNode] Not a range selection — skipping unwrap.');
    return;
  }

  const selectedNodes = selection.getNodes();
  const wrappersToUnwrap = new Set<ElementNode>();

  // Step 1: Find all unique IXBRL wrappers in the selection
  for (const node of selectedNodes) {
    let current: LexicalNode | null = node;

    while (current !== null) {
      if ($isXbrlNode(current) && $isElementNode(current)) {
        wrappersToUnwrap.add(current);
        break;
      }
      current = current.getParent();
    }
  }

  let firstUnwrapped: LexicalNode | null = null;
  let lastUnwrapped: LexicalNode | null = null;

  // Step 2: Unwrap each IXBRLNode
  for (const wrapper of wrappersToUnwrap) {
    const parent = wrapper.getParent();

    if (!$isElementNode(parent)) {
      console.warn('[$unwrapIxbrlNode] IXBRLNode has no valid parent. Skipping.');
      continue;
    }

    const children = wrapper.getChildren();

    for (const child of children) {
      wrapper.insertBefore(child); // moves child before wrapper
      if (firstUnwrapped === null) {
        firstUnwrapped = child;
      }
      lastUnwrapped = child;
    }

    wrapper.remove();
  }

  // Step 3: Restore selection
  if (firstUnwrapped && lastUnwrapped) {
    const newSelection = $createRangeSelection();

    newSelection.anchor.set(
      firstUnwrapped.getKey(),
      0,
      $isElementNode(firstUnwrapped) ? 'element' : 'text'
    );

    newSelection.focus.set(
      lastUnwrapped.getKey(),
      $isElementNode(lastUnwrapped)
        ? 0
        : lastUnwrapped.getTextContentSize(),
      $isElementNode(lastUnwrapped) ? 'element' : 'text'
    );

    $setSelection(newSelection);
  }
}
