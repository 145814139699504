import { QUESTION } from '@constants/terminology';
import { NewEvidenceFile } from '@g17eco/types/file';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { EvidenceFile } from '../questionInterfaces';
import { isNewEvidence } from '@components/survey/utils/evidence';

interface Props {
  action?: string;
  notApplicableType?: string;
  autoVerify?: boolean;
  files: EvidenceFile[];
  handleClose: () => void;
  handleSubmitQuestion: (params: {
    action: string;
    notApplicableType?: string;
    autoVerify?: boolean;
    files: EvidenceFile[];
    addToLibrary?: boolean;
  }) => void;
}

export const AddFileLibraryModal = ({
  action,
  autoVerify,
  notApplicableType,
  files,
  handleClose,
  handleSubmitQuestion,
}: Props) => {
  const newFiles = files.filter(isNewEvidence);
  const handleSubmit = ({ addToLibrary }: { addToLibrary: boolean }) => {
    if (!action) {
      handleClose();
      return;
    }
    if (!addToLibrary) {
      handleSubmitQuestion({ action, notApplicableType, autoVerify, files, addToLibrary });
    } else {
      const markedAsLibraryFiles = files.map((file) => {
        if (isNewEvidence(file)) {
          return { ...file, saveToLibrary: true };
        }
        return file;
      });
      handleSubmitQuestion({ action, notApplicableType, autoVerify, files: markedAsLibraryFiles, addToLibrary });
    }
    handleClose();
  };

  return (
    <Modal isOpen={action !== undefined} backdrop='static'>
      <ModalHeader>Add files to Document library?</ModalHeader>
      <ModalBody>
        {newFiles.map((newFile, index) => (
          <div key={`${newFile.file.name}-${index}`}>
            <p>{newFile.file.name}</p>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' className='mr-3' onClick={() => handleSubmit({ addToLibrary: false })}>
          {`This ${QUESTION.SINGULAR} only`}
        </Button>
        <Button color='primary' onClick={() => handleSubmit({ addToLibrary: true })}>
          {`Save to ${QUESTION.SINGULAR} & library`}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
