import { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { EvidenceFile } from '../survey/question/questionInterfaces';
import { HandleFileDescriptionFn } from '../../types/file';

interface ImageDescriptionProps {
  photo: EvidenceFile;
  handleFileDescriptionAdd?: HandleFileDescriptionFn;
  isReadOnly: boolean;
}

export const FileDescription = ({ photo, handleFileDescriptionAdd, isReadOnly }: ImageDescriptionProps) => {
  const [description, setDescription] = useState('');
  const path = 'path' in photo ? photo.path : photo.file.path;
  const isUpdate = 'path' in photo;
  if (photo.description) {
    return <span className='file-description text-ThemeTextMedium'>{photo.description}</span>;
  }

  if (isReadOnly) {
    return null;
  }

  return (
    <div className='img-description d-flex h-100 gap-1 align-items-center'>
      <Input
        key='img-description'
        type='text'
        placeholder='Page reference if applicable'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button
        color='primary'
        disabled={!description}
        onClick={() => handleFileDescriptionAdd?.({ path, description, isUpdate })}
      >
        Add
      </Button>
    </div>
  );
};
